/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /*
         * Get Viewport Dimensions
         * returns object with viewport dimensions to match css in width and height properties
        */
        function updateViewportDimensions() {
          var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
          return { width:x,height:y };
        }
        // setting the viewport width
        var viewport = updateViewportDimensions();

        /*
         * Throttle Resize-triggered Events
         * Wrap your actions in this function to throttle the frequency of firing them off, for better performance, esp. on mobile.
        */
        var waitForFinalEvent = (function () {
          var timers = {};
          return function (callback, ms, uniqueId) {
            if (!uniqueId) { uniqueId = "Don't call this twice without a uniqueId"; }
            if (timers[uniqueId]) { clearTimeout (timers[uniqueId]); }
            timers[uniqueId] = setTimeout(callback, ms);
          };
        })();

        $( '.acf-tab-group li a' ).click( function(e) {
          e.preventDefault();
        });

        $(window).load(function() {
          $('#status').fadeOut();
          $('#preloader').delay(350).fadeOut('slow');
          setTimeout(function() {
            $('body').delay(1350).css({'overflow':'visible'});
          }, 1250);
        });

        var timeToWaitForLast = 100;

        //smoothScroll
        $('a[href^="#"]').live('click',function(event){
          if($(event.target).attr('href') != '#') {
            event.preventDefault();
            var target_offset = $(this.hash).offset() ? $(this.hash).offset().top : 0;
            var customoffset = 85;
            $('html, body').animate({scrollTop:target_offset - customoffset}, 500);
          }
        });

        // EQUAL HEIGHT ELEMENTS ON PROCESS
        var equalheight;
        equalheight = function(container){

          var currentTallest = 0,
          currentRowStart = 0,
          currentDiv = 0,
          rowDivs = new Array(),
          $el,
          topPostion = 0;
          $(container).each(function() {
            $el = $(this);
            $($el).height('auto');
            topPostion = $el.position().top;

            if (currentRowStart !== topPostion) {
              for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
              }
              rowDivs.length = 0; // empty the array
              currentRowStart = topPostion;
              currentTallest = $el.height();
              rowDivs.push($el);
            } else {
              rowDivs.push($el);
              currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
            }
            for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }
          });
        };

        if( viewport.width >= 992 ) {
          equalheight('.layout-two-col-text .eq');
        }

        /* PARALLAX BG */
        $(window).scroll(function () {
          $('#page-title').css({
            'top' : ($(this).scrollTop()/4)+"px"
          });
        });

        // ScrollReveal Global
        window.sr = new ScrollReveal();

        //Search Toggle

        function animateDiv (event) {
          $('.input-wrapper').toggleClass('showMe');
          $('.btn-calculator').toggleClass('showMe');
          var showMe = $('.input-wrapper').hasClass('showMe');
          $('.input-wrapper').animate({
            width: showMe ? '150px' : '0px',
          },350);
          $('.logged-out .btn-member-entry').animate({
            paddingRight: showMe ? '18px' : '40px',
          },250);
          $('.logged-in .btn-sign-out').animate({
            paddingRight: showMe ? '18px' : '35px',
          },250);
        }

        $('.searchform .btn-submit').on('click', function(event) {
          event.preventDefault();
          animateDiv();
          return false;
        });

        $( 'a[href="#"]' ).click( function(e) {
          e.preventDefault();
        });

        $("#press-release-carousel").owlCarousel({
          nav:true,
          margin: 25,
          navText: [
             "<i class='icon-left-open-big'></i>",
             "<i class='icon-right-open-big'></i>"
          ],
          slideSpeed : 300,
          paginationSpeed : 4000,
          loop: false,
          dots: false,
          autoplay: false,
          responsive : {
            0 : {
              items:1
            },
            480 : {
                items:1
            },
            800 : {
                items:2
            }
          }
        });

        //Sticky Nav
        var nav;
        function yScroll(){
            nav = $('.nav-elements');
            yPos = window.pageYOffset;
            nav.removeClass("docked");
            if(yPos > 150){
              $("body").addClass("scrolly");
              nav.addClass("docked");
            }
            else {
              $('body').removeClass("scrolly");
              nav.removeClass("docked");
            }
        }
        window.addEventListener("scroll", yScroll);

        //MMENU Mobile Nav
        $("#the-menu").mmenu({
          "extensions": [
            "pagedim-black"
         ],
          searchfield: {
               add: true,
                search: false
            }
        }, {
           // configuration
           clone: true,
           offCanvas: {
              pageSelector: "#the-page"
           }
        });

        $("#mm-the-menu input")
            .bind( "change", function() {
                // do your search

                // maybe close the menu?
                $("#mm-the-menu").trigger( "close" );
            }
        );

        // accordion for search + filter
        function accordionSearchFilter(id, state) {
          state = (state ? state : {});
          if(!state.listeners) {
            $(document).on('sf:ajaxstart', function() {
              $('#search-filter-form-'+id).find('li[data-sf-field-type="taxonomy"]').each(function() {
                state[$(this).attr('data-sf-field-name')] = !$(this).find('> ul').hasClass('shrink');
              });
            });
            $(document).on('sf:ajaxfinish', function() {
              accordionSearchFilter(id, state);
            });
            state.listeners = true;
          }
          $('#search-filter-form-'+id+' li[data-sf-field-type="taxonomy"]').each(function() {

            var $li = $(this);
            var $list = $li.find('> ul');
            $list.css('height', $list.height());
            if(!state[$li.attr('data-sf-field-name')]) {
              $list.addClass('shrink');
            }
            var $toggle = $('<span class="toggle">'+(state[$li.attr('data-sf-field-name')] ? '&ndash;' : '+')+'</span>');
            $li.find('h4').prepend($toggle);
            $toggle.click(function() {
              $list.toggleClass('shrink');
              if($(this).html() == '+') {
                $(this).html('&ndash;');
              } else {
                $(this).html('+');
              }
            });
          });
        }

        accordionSearchFilter(2579);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.scroll-dwn').hide().delay(2000).fadeIn(500).addClass('done');
      },
      finalize: function() {
        sr.reveal( '#home-video h1', { delay: 1000 } );
        sr.reveal( '#home-program figure:first-child', { viewFactor: 0.5, delay: 400, mobile: false } );
        sr.reveal( '#home-program figure:nth-child(2)', { viewFactor: 0.5, delay: 800, mobile: false } );
        sr.reveal( '#home-program figure:last-child', { viewFactor: 0.5, delay: 1200, mobile: false } );
        sr.reveal( '#home-program .btn-plus', { viewFactor: 0.5, delay: 1000 } );
        sr.reveal( '#home-tools-resources img, .home .icon-fieldprint, .home .icon-national-indicator', { viewFactor: 0.6, scale: 0.1, mobile: false } );
        sr.reveal( '#home-members .container', { viewFactor: 0.9, mobile: false } );
        sr.reveal( '#home-partner-impact img, .home .icon-partnering-impact', { viewFactor: 0.6, mobile: false } );

        //$('.scroll-dwn').addClass('animate').one('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', function(){$(this).removeClass('animate').addClass('done')});
        $(window).scroll(function () {
          var scrollTop = $(window).scrollTop() * 2.5;
          var height = $(window).height();

          $('.scroll-dwn.done').css({
              'opacity': (((height - scrollTop) / height))
          });
        });

        //home member carousel
        $("#home-member-carousel,#home-farmer-carousel").owlCarousel({
          nav:true,
          navText: [
             "<i class='icon-left-open-big'></i>",
             "<i class='icon-right-open-big'></i>"
          ],
          slideSpeed : 300,
          paginationSpeed : 4000,
          loop: true,
          dots: false,
          items: 1,
          autoplay: true,
          autoplayTimeout:8000
          //animateOut: 'fadeOut'
        });
      }
    },

    // JOIN US
    'page_id_8': {
      init: function() {

      },
      finalize: function() {

        //Twitter Plugin Hack

        $('.aptf-timestamp a').text(function () {
          return $(this).text().replace("-", "");
        });
      }
    },

    // JOIN US
    'page_id_15': {
      init: function() {

      },
      finalize: function() {

        //home member carousel
        $("#member-carousel").owlCarousel({
          nav:true,
          navText: [
             "<i class='icon-left-open-big'></i>",
             "<i class='icon-right-open-big'></i>"
          ],
          slideSpeed : 300,
          paginationSpeed : 4000,
          loop: true,
          dots: false,
          items: 1,
          autoplay: true,
          autoplayTimeout:8000
          //animateOut: 'fadeOut'
        });
      }
    },

    // OUR HISTORY
    'page_id_12': {
      init: function() {

      },
      finalize: function() {

        /* PARALLAX BG */
        $(window).scroll(function () {
          $('#sky-elements').css({
            'top' : ($(this).scrollTop()/6)+"px"
          });
        });

       (function() {
        'use strict';

        // define variables
        var items = document.querySelectorAll(".timeline li");

        // check if an element is in viewport
        // http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
        function isElementInViewport(el) {
          var rect = el.getBoundingClientRect();
          return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
          );
        }

        function callbackFunc() {
          for (var i = 0; i < items.length; i++) {
            if (isElementInViewport(items[i])) {
              items[i].classList.add("in-view");
            }
          }
        }

        // listen for events
        window.addEventListener("load", callbackFunc);
        window.addEventListener("resize", callbackFunc);
        window.addEventListener("scroll", callbackFunc);

      })();
      }
    },

    // FIELDPRINT PLATFORM
    'page_id_87': {
      init: function() {

      },
      finalize: function() {

        // member carousel
        $("#farmer-carousel").owlCarousel({
          nav:true,
          navText: [
             "<i class='icon-left-open-big'></i>",
             "<i class='icon-right-open-big'></i>"
          ],
          slideSpeed : 300,
          paginationSpeed : 4000,
          loop: true,
          dots: false,
          items: 1,
          autoplay: true,
          autoplayTimeout:8000
          //animateOut: 'fadeOut'
        });
      }
    },

    // FIELDPRINT PROJECT DIRECTORY
    'page_id_19': {
      init: function() {
        var regions = [];
      },
      finalize: function() {

        // case study carousel
        $("#case-study-carousel").owlCarousel({
          nav:true,
          navText: [
             "<i class='icon-left-open-big'></i>",
             "<i class='icon-right-open-big'></i>"
          ],
          slideSpeed : 300,
          paginationSpeed : 4000,
          loop: false,
          dots: false,
          items: 1,
          autoplay: true,
          autoplayTimeout:8000
          //animateOut: 'fadeOut'
        });

        // Store currentRegion
        //var currentRegion = 'fl';

        // List of Regions we'll let clicks through for
        //var enabledRegions = ['mo', 'fl', 'or'];

        //map thing
        $('#vmap').vectorMap({
            multiSelectRegion: false,
            map: 'usa_en',
            borderColor: '#ffffff',
            backgroundColor: null,
            color: '#d2dde1',
            enableZoom: false,
            showTooltip: true,
            hoverColor: null,
            selectedColor: '#f9903f',
            colors: colorObj,
            onRegionOver:function(event, code, region) {
              if(ftmLocalizedData.states.indexOf(code.toUpperCase()) == -1) {
                event.preventDefault();
                return false;
              }
            },
            onRegionClick: function(event, code, region){
              if(ftmLocalizedData.states.indexOf(code.toUpperCase()) == -1) {
                event.preventDefault();
                return false;
              }
            },
            onRegionSelect: updateJqMap,
            onRegionDeselect: updateJqMap
        });
      }
    },

    'page_id_50': {
      finalize: function() {
        var options = {
          page:10,
          plugins: [
            ListPagination({})
          ],
          valueNames: [
            'name',
            {
              data: [
                'cf-organization',
                'mf-sector',
                'governing-body'
              ]
            },
          ]
        };
        var filters = {
          'cf-organization': '',
          'mf-sector': '',
          'governing-body': '',
          'name': '',
        };
        var memberList = new List('member-list', options);
        memberList.on('updated', function() {
          if(!memberList.matchingItems.length) {
            if(filtersAreEmpty(filters)) {
              $('.needs-input').removeClass('hide');
            } else {
              $('.no-results').removeClass('hide');
            }
          } else {
            $('.no-results').addClass('hide');
            $('.needs-input').addClass('hide');
          }
        });

        applyListFilters(memberList, filters);

        $('.search-field').on('input change', function() {
          var val = $(this).val();
          var key = $(this).attr('name');
          filters[key] = val;
          applyListFilters(memberList, filters);
        });

        function filtersAreEmpty(filters) {
          for (var filter in filters) {
            if(filters[filter] != '') {
              return false;
            }
          }
          return true;
        }

        function applyListFilters(list, filters) {
          var filtersEmpty = filtersAreEmpty(filters);
          console.log(filtersEmpty);
          list.filter(function(item) {
            if(filtersEmpty) {
              return false;
            }
            for (var filter in filters) {
              if(filters[filter] == '') {
                continue;
              }
              if (filters.hasOwnProperty(filter)) {
                if(item.values()[filter] == null || !item.values()[filter].toLowerCase().includes(filters[filter].toLowerCase())) {
                  return false;
                }
              }
            }
            return true;
          });
        }
      }
    },

    // FIELDPRINT PROJECT DIRECTORY MEMBERS
    'page_id_51': {
      init: function() {

      },
      finalize: function() {

        // Store currentRegion
        //var currentRegion = 'fl';

        // List of Regions we'll let clicks through for
        //var enabledRegions = ['mo', 'fl', 'or'];

        //map thing
        $('#vmap').vectorMap({
            multiSelectRegion: false,
            map: 'usa_en',
            borderColor: '#ffffff',
            backgroundColor: null,
            color: '#d2dde1',
            enableZoom: false,
            showTooltip: true,
            hoverColor: null,
            selectedColor: '#f9903f',
            colors: colorObj,
            onRegionOver:function(event, code, region) {
              if(ftmLocalizedData.states.indexOf(code.toUpperCase()) == -1) {
                event.preventDefault();
                return false;
              }
            },
            onRegionClick: function(event, code, region){
              if(ftmLocalizedData.states.indexOf(code.toUpperCase()) == -1) {
                event.preventDefault();
                return false;
              }
            },
            onRegionSelect: updateJqMap,
            onRegionDeselect: updateJqMap
        });
      }
    },

    // OUR MEMBERS
    'page_id_6': {
      finalize: function() {
        //home member carousel
        $("#member-carousel").owlCarousel({
          nav:true,
          navText: [
             "<i class='icon-left-open-big'></i>",
             "<i class='icon-right-open-big'></i>"
          ],
          slideSpeed : 300,
          paginationSpeed : 4000,
          loop: true,
          dots: false,
          items: 1,
          autoplay: true,
          autoplayTimeout:8000
          //animateOut: 'fadeOut'
        });

        //our members full
        $(window).on("load", function () {
          // init Isotope
          var $container = $('.member-grid.full');
          $container.imagesLoaded( function(){
            $container.isotope({
              layoutMode: 'fitRows',
              itemSelector: '.grid-item',
              percentPosition: true,
              fitRows: {
                columnWidth: '.grid-sizer',
                gutter: '.gutter-sizer'
              }
            });
            // bind filter button click
            $('.fb-01').on( 'click', 'button', function() {
              var filterValue = $( this ).attr('data-filter');
              $container.isotope({ filter: filterValue });
            });
            // change is-checked class on buttons
            $('.fb-01.button-group').each( function( i, buttonGroup ) {
              var $buttonGroup = $( buttonGroup );
              $buttonGroup.on( 'click', 'button', function() {
                $buttonGroup.find('.is-checked').removeClass('is-checked');
                $( this ).addClass('is-checked');
              });
            });
          });
        });

        //our members associate
        $(window).on("load", function () {
          // init Isotope
          var $container2 = $('.member-grid.associate');
          $container2.imagesLoaded( function(){
            $container2.isotope({
              layoutMode: 'fitRows',
              itemSelector: '.grid-item',
              percentPosition: true,
              fitRows: {
                columnWidth: '.grid-sizer',
                gutter: '.gutter-sizer'
              }
            });
            // bind filter button click
            $('.fb-02').on( 'click', 'button', function() {
              var filterValue = $( this ).attr('data-filter');
              $container2.isotope({ filter: filterValue });
            });
            // change is-checked class on buttons
            $('.fb-02.button-group').each( function( i, buttonGroup ) {
              var $buttonGroup2 = $( buttonGroup );
              $buttonGroup2.on( 'click', 'button', function() {
                $buttonGroup2.find('.is-checked').removeClass('is-checked');
                $( this ).addClass('is-checked');
              });
            });
          });
        });
      }//end
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

function updateJqMap(event, code) {
  var map = jQuery(event.currentTarget).data('mapObject');
  var regions = [];
  jQuery.each(map.selectedRegions, function(index, value) {
    regions.push(abbrState(value, 'name').toLowerCase().replace(' ', '-'));
  });
  jQuery('select[name="_sfm_cf-project-state[]"]').val(regions).focus().change();

  if(regions.length) {
    jQuery('.states-result span').html(regions[0].replace('-', ' '));
  } else {
    jQuery('.states-result span').html('All States');
  }
}

function abbrState(input, to){

    var states = [
        ['Arizona', 'AZ'],
        ['Alabama', 'AL'],
        ['Alaska', 'AK'],
        ['Arizona', 'AZ'],
        ['Arkansas', 'AR'],
        ['California', 'CA'],
        ['Colorado', 'CO'],
        ['Connecticut', 'CT'],
        ['Delaware', 'DE'],
        ['Florida', 'FL'],
        ['Georgia', 'GA'],
        ['Hawaii', 'HI'],
        ['Idaho', 'ID'],
        ['Illinois', 'IL'],
        ['Indiana', 'IN'],
        ['Iowa', 'IA'],
        ['Kansas', 'KS'],
        ['Kentucky', 'KY'],
        ['Kentucky', 'KY'],
        ['Louisiana', 'LA'],
        ['Maine', 'ME'],
        ['Maryland', 'MD'],
        ['Massachusetts', 'MA'],
        ['Michigan', 'MI'],
        ['Minnesota', 'MN'],
        ['Mississippi', 'MS'],
        ['Missouri', 'MO'],
        ['Montana', 'MT'],
        ['Nebraska', 'NE'],
        ['Nevada', 'NV'],
        ['New Hampshire', 'NH'],
        ['New Jersey', 'NJ'],
        ['New Mexico', 'NM'],
        ['New York', 'NY'],
        ['North Carolina', 'NC'],
        ['North Dakota', 'ND'],
        ['Ohio', 'OH'],
        ['Oklahoma', 'OK'],
        ['Oregon', 'OR'],
        ['Pennsylvania', 'PA'],
        ['Rhode Island', 'RI'],
        ['South Carolina', 'SC'],
        ['South Dakota', 'SD'],
        ['Tennessee', 'TN'],
        ['Texas', 'TX'],
        ['Utah', 'UT'],
        ['Vermont', 'VT'],
        ['Virginia', 'VA'],
        ['Washington', 'WA'],
        ['West Virginia', 'WV'],
        ['Wisconsin', 'WI'],
        ['Wyoming', 'WY'],
    ];

    if (to === 'abbr'){
        input = input.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        for(i = 0; i < states.length; i++){
            if(states[i][0] === input){
                return(states[i][1]);
            }
        }
    } else if (to === 'name'){
        input = input.toUpperCase();
        for(i = 0; i < states.length; i++){
            if(states[i][1] === input){
                return(states[i][0]);
            }
        }
    }
}

var colorObj = {};
// see extras.php line 181ish for where this data is created
//console.log(ftmLocalizedData.states);
ftmLocalizedData.states.forEach(function(value, index) {
  colorObj[value.toLowerCase()] = '#79b2c3';
});

jQuery(document).ready(function($) {
  $('#vmap-intro .btn').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $('#vmap').vectorMap('set', 'selectedRegions', []);
    return false;
  });
});
